.header {
  border-bottom: 1px solid $gray-400;
}

// naver map

$full-width: 100vw;
$full-height: 100vh;
$full-height-with-tc: calc(#{$full-height} - 35px);
// Desktop mode
.time-controller {
  position: fixed;
  bottom: 0;
}

@include media-breakpoint-up(lg) {
  .main-map,
  .dashboard-map,
  .full-height {
    width: calc(#{$full-width} - #{get($aside-config, width)});
    height: calc(#{$full-height} - #{get($header-config, fixed, height, desktop)});

    // Minimize aside mode
    [data-kt-aside-minimize='on'] & {
      width: calc(#{$full-width} - #{get($aside-config, minimized-width)});
    }
  }
  .dashboard-map {
    height: calc(#{$full-height-with-tc} - #{get($header-config, fixed, height, desktop)});
  }
  .time-controller {
    width: calc(#{$full-width} - #{get($aside-config, width)});

    [data-kt-aside-minimize='on'] & {
      width: calc(#{$full-width} - #{get($aside-config, minimized-width)});
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .main-map,
  .dashboard-map,
  .full-height {
    width: $full-width;
    height: calc(#{$full-height} - #{get($header-config, fixed, height, tablet-and-mobile)});
  }
  .dashboard-map {
    height: calc(#{$full-height-with-tc} - #{get($header-config, fixed, height, tablet-and-mobile)});
  }
  .time-controller {
    width: $full-width;
  }
}

// Markdown

.md-wrapper {
  h1 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  h2 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important;
  }

  pre {
    border: 0;
    box-shadow: $card-box-shadow;
    background-color: $card-bg;
    border-radius: $card-border-radius;
    padding: $card-py $card-px;

    code {
      font-family: $font-family-code !important;
      font-size: $code-font-size;

      &[class*='language-'] {
        color: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-shadow: none;
      }
    }
  }
}

// Dashboard
.search-map {
  width: 100%;
  height: 600px;
}
